

// import * as firebase from 'firebase/app';
// import 'firebase/firestore';

import { firestore } from 'firebase';
import firebase from 'firebase';
import { SCF_genUUID } from '../SkillCappedFunctions';
import { FirestoreQuery } from './FirestoreQuery';



export abstract class SyncedQueryResult<T>{
    abstract changed:T[];
    abstract added:T[];
    abstract deleted:T[];



    static create<T>():SyncedQueryResult<T>{
        return {
            
            added:[],
            changed:[],
            deleted:[]
        }
    }

}

export interface ObjectMap<T>{
    [key:string] : T
}


export class SimpleFirestoreManger{
    private static multiQueryMap:Map<string,{unsub:()=>void}>  = new Map();

    static queries = new Map<String,any>();
    static db:firestore.Firestore;

    static getDb(){
        return SimpleFirestoreManger.db;
    }

    static generateUUID(){
        return SCF_genUUID();
    }

    private static replaceObjectsTimestamps(obj:any):any{
        for(let key in obj){
            if(key.indexOf("serv_")===0){
                let val = obj[key];
                if(!val){
                    //this is the first time we are submitting
                    val = new Date().getTime();
                }
                if(val.getTime){
                    obj[key.replace("serv_","")]=val.getTime();
                }if(val.toMillis){
                    obj[key.replace("serv_","")]=val.toMillis();
                }
            }
        }
    }


    static getUpdateDeleteKey(){
        return firebase.firestore.FieldValue.delete();
    }

    static createSyncedMultiDocQuery<T>(collectionName:string,querySpecifier:FirestoreQuery<T>,callback:(result:SyncedQueryResult<T>)=>void):()=>void{
        
 

  
        let collection = SimpleFirestoreManger.getDb().collection(collectionName);
        
        let query:firebase.firestore.Query = collection;
        for(let param of querySpecifier){
            query = query.where(param[0],param[1],param[2]);
        }

        let unsub = query.onSnapshot((snapshot)=>{

            let ret = SyncedQueryResult.create<T>();



            //try to ignore this error
            snapshot.docChanges().forEach((change)=>{
                let obj = <T> change.doc.data();

                //update server stuff
                SimpleFirestoreManger.replaceObjectsTimestamps(obj);


                if(change.type === "added"){
                    ret.added.push(obj);
                }

                if(change.type === "modified"){
                    ret.changed.push(obj);
                }

                if(change.type === "removed"){
                    ret.deleted.push(obj);
                }
            })


            callback(ret);
        },(error)=>{
            console.error(`ERROR WITH QUERY collection:${collectionName}, ${JSON.stringify(querySpecifier)}`)
            console.error(error)
            throw error;
        })

        return unsub;
    }

    static createOrUpdateNamedMultiDocQuery<T>(name:string,collectionName:string,querySpecifier:FirestoreQuery<T>,callback:(result:SyncedQueryResult<T>)=>void){
        this.unsubFromNamedQuery(name);
        
        let unsub = SimpleFirestoreManger.createSyncedMultiDocQuery<T>(collectionName,querySpecifier,callback);
        SimpleFirestoreManger.multiQueryMap.set(name,{unsub})
    }

    static mergeQueryResultsIntoMap<T>(queryResults:SyncedQueryResult<T>,map:ObjectMap<T>, mapBy:(obj:T)=>string){

        let getAndWriteId=(obj:T)=>{
            let id = mapBy(obj);
            map[id]= obj;
        }


        for(let obj of queryResults.added){
            getAndWriteId(obj);
        }

        for(let obj of queryResults.changed){
            getAndWriteId(obj);
        }

        for(let obj of queryResults.deleted){
            delete map[mapBy(obj)];
        }
    }

    static unsubFromNamedQuery(name:string){
        let multiQueryInfo = this.multiQueryMap.get(name);
        if(multiQueryInfo && multiQueryInfo.unsub)multiQueryInfo.unsub();
    }

    static addOrReplaceSingleDocQuery<T>(queryName:string,collectionName:string,docId:string,callback:(param:T)=>void){

        SimpleFirestoreManger.unsubQuery(queryName);
        
        let unsub = SimpleFirestoreManger.getDb().collection(collectionName).doc(docId).onSnapshot((snapshot)=>{
            let data:any = null;
            if(snapshot.exists){
                data = snapshot.data();
            }
            callback(data as any);
        },(err)=>{console.error(err)});

        SimpleFirestoreManger.queries.set(queryName,unsub);

    }

    static getSpecialServerTimestampValue(){
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    static saveDocument(collectionName:string,id:string,data:any,extraErrorMessage:string=""){
        return SimpleFirestoreManger.getDb().collection(collectionName).doc(id).set(data).catch((err) => {
            let error = new Error(`${extraErrorMessage} ERROR: ${err.message}`);
            throw error;
		});
    }

    static deleteDocument(collectionName:string,id:string,extraErrorMessage:string=""){
        return SimpleFirestoreManger.getDb().collection(collectionName).doc(id).delete().catch((err) => {
            let error = new Error(`${extraErrorMessage} ERROR: ${err.message}`);
            throw error;
		});
    }

    static unsubQuery(queryName:string){
        let unsub = SimpleFirestoreManger.queries.get(queryName);
        if(unsub)unsub();

    }
}