
import { observable, action } from "mobx";


export class StoreLocation{
    private static _inst:StoreLocation;

    public static get inst():StoreLocation{
        if(!StoreLocation._inst) StoreLocation._inst = new StoreLocation();
        return StoreLocation._inst;
    }

    @observable state_path="";


    @action
    setPath(path:string){
        this.state_path=path;
    }




}

export const SLS = StoreLocation;