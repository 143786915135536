import { Interpolation, css } from "emotion";
import { FlexDirectionProperty } from "csstype";

export type T_FLEX_HORIZONTAL = "left"|"cen"|"right"|"sb"|"se";
export type T_FLEX_VERTICAL="top"|"cen"|"bot"|"sb"|"se";
export type T_FLEX_DIRECTION="row"|"col";

export class StyleGen{

    static MQ_MOBILE="@media screen and (max-width: 660px)";

    static font(size:number,color:string|null=null,weight="normal",transform:"uppercase"|"none"="none"):string{
        color = color || "black";

        return css({
            color,
            fontSize:size,
            fontWeight:weight as any,
            textTransform:transform as any
        })

    }

    static flex(direction:"row"|"col",horizontal:T_FLEX_HORIZONTAL="left",vertical:T_FLEX_VERTICAL="top"):string{
        return css(this.flexCSS(direction,horizontal,vertical));

    }

    static margin(top:number,right=0,bottom=0,left=0):string{
        return css({margin:`${top}px ${right}px ${bottom}px ${left}px`})
    }

    static paddingI(top=0,right=0,bottom=0,left=0){
        let s:Interpolation<undefined>={};
        if(top)s.paddingTop = top;
        if(right)s.paddingRight = right;
        if(left)s.paddingLeft = left;
        if(bottom)s.paddingBottom = bottom;
        return s;
    }

    static transition(time:string){
        return css({transition:time + " all"})
    }

    static padding(top=0,right=0,bottom=0,left=0):string{
        return css(this.paddingI(top,right,bottom,left));
    }

    static w100(){
        return css({width:"100%"})
    }

    static bgC(color:string){
        return css({backgroundColor:color})
    }

    static flexCSS(direction:"row"|"col",horizontal:T_FLEX_HORIZONTAL="left",vertical:T_FLEX_VERTICAL="top"):Interpolation<undefined>{

        let dirMap : {[key:string]:FlexDirectionProperty} = {
            "row":"row",
            "col":"column"
        }

        let ret:Interpolation<undefined> = {
            display:"flex",
            flexDirection:dirMap[direction]
        }


        let valueMap = {
            "se" :"space-evenly",
            "sb" :"space-between",
            "left":"flex-start",
            "top":"flex-start",
            "cen":"center",
            "right":"flex-end",
            "bot":"flex-end"
        }

        

        
        if(direction == "row"){
            ret.justifyContent = valueMap[horizontal];
            ret.alignItems = valueMap[vertical];
            
        }else if(direction == "col"){
            ret.justifyContent = valueMap[vertical];
            ret.alignItems = valueMap[horizontal];
        }

        return ret;

    }

    static cssOverride(cssIn:Interpolation<undefined>){
        return css({"&&":cssIn})
    }
}

export const SG = StyleGen;