

import * as React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";
import { SG } from "../../helpers/StyleGen";
import "./css-global/global.scss";

interface PageGlobalProps {}
interface PageGlobalState {}




@observer
export class PageGlobal extends React.Component<PageGlobalProps, PageGlobalState> {





    render() {
        
        
        return(
            <div className={cx(SG.flex("col","cen","top"), css({minHeight:"100vh"},))}>
                {this.props.children}
            </div>
        );
    }
}
