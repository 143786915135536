

import * as React from "react";
import { observer } from "mobx-react";
import { cx } from "emotion";
import { ElemFlex } from "./ElemFlex";
import { T_FLEX_HORIZONTAL, T_FLEX_VERTICAL } from "../../../helpers/StyleGen";


interface ElemFlexColProps {
    h?:T_FLEX_HORIZONTAL;
    v?:T_FLEX_VERTICAL;
    className?:string;
    //not 100 width
    n100?:boolean
}
interface ElemFlexRowState {}




@observer
export class ElemFlexCol extends React.Component<ElemFlexColProps, ElemFlexRowState> {

    constructor(props: any) {
        super(props);
    }



    render() {

        return(
            <ElemFlex n100={this.props.n100} dir="col" v={this.props.v} h={this.props.h} className={this.props.className}>
                {this.props.children}
            </ElemFlex>
        );
    }
}
