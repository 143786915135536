import React from 'react';
import ReactDOM from 'react-dom';
import { PageMain } from './frontend/components/PageMain';
import { ServiceFireStore } from './frontend/services/ServiceFireStore';
import { SimpleFirestoreManger } from './frontend/services/firestore/SimpleFirestoreManager';
import * as serviceWorker from './frontend/services/serviceWorker';


//setup stuff here.
ServiceFireStore.init();
SimpleFirestoreManger.db = ServiceFireStore.firestoreDb();


ReactDOM.render(<PageMain />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
