
export interface Json {
    [x: string]: string|number|boolean|Date|Json|JsonArray|null;
}
interface JsonArray extends Array<string|number|boolean|Date|Json|JsonArray> { }

export type ActionFunction<T extends Json=any,R=any> = (data:T)=>R


export class StoreActions{
    private static _inst:StoreActions;

    public static get inst():StoreActions{
        if(!StoreActions._inst) StoreActions._inst = new StoreActions();
        return StoreActions._inst;
    }

    functionRegister = new Map<string,ActionFunction>();


    fireAction(actionName:string,data:Json){
        console.log("ACTION:"+actionName,JSON.parse(JSON.stringify(data)));
        let ah = this.functionRegister.get(actionName);
        if(!ah) throw new Error("no action found: " + actionName);
        return ah(data);
    }


    createActionFunction<T extends Json,R=any>(actionName:string,handler:ActionFunction<T,R>){

        if(this.functionRegister.has(actionName)){
            throw new Error("this action name is already registered: " + actionName);
        }

        this.functionRegister.set(actionName,handler);

        return (data:T):R=>{
            return this.fireAction(actionName,data);
        }
    }




}

//This store will be used everywhere so just go ahead and create it immediately and alias it.
export const SA = StoreActions.inst;