

import * as React from "react";
import { observer } from "mobx-react";
import { ElemFlexRow } from "../common/alignment/ElemFlexRow";
import { cx, css } from "emotion";
import TextField from '@material-ui/core/TextField';
import { observable, action, computed, runInAction } from "mobx";
import { Button, FormControl, InputLabel, Select, MenuItem, IconButton } from "@material-ui/core";
import { HelperTime, ONE_SECOND, ONE_MINUTE } from "../../helpers/HelperTime";
import { SG } from "../../helpers/StyleGen";
import { StoreTimeEntries } from "../../services/ph/StoreTimeEntries";
import { SCF_waitFor } from "../../services/SkillCappedFunctions";
import AddIcon from '@material-ui/icons/Add';
import { SA } from "../../stores/StoreActions";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

interface ElemAddTimeProps { }
interface ElemAddTimeState { }



class TextInputState {
    @observable value = "";

    constructor(initialValue = "") {
        this.value = initialValue;
    }

    onChange = (e: any) => {
        this.value = e.target.value;
    }
}

function requestionNotification() {
    if ((window as any)["Notification"]) {
        if (Notification.permission !== 'granted') {
            Notification.requestPermission();
        }
    }
}


type TIMER_RUN_STATE = "not_started" | "running" | "paused";



@observer
export class ElemAddTime extends React.Component<ElemAddTimeProps, ElemAddTimeState> {

    @observable rs = this.genDefaultRS();

    curNotification: Notification | null = null;

    autoAddedCount = 0;

    constructor(props: any) {
        super(props);

        let json = localStorage.getItem("timer_state");
        if (json) {
            let data = JSON.parse(json);

            delete data.timeInput;
            runInAction(() => {
                this.rs = Object.assign(this.rs, data);
            })
        }

        this.handleTimeCheck();

        (window as any)["testfn"] = () => {
            this.sendNotification();
        }

        //Request permission if we don't have it
        requestionNotification();
    }

    @action
    handleTimeCheck = () => {

        //if we are running
        if (this.rs.lastStartTime !== -1) {

            //total time includes all pauses and previous stuff so you can't just add to it.
            let currentTotal = (Date.now() - this.rs.lastStartTime) + this.rs.totalTime;
            this.rs.curTotalTime = currentTotal;
            this.rs.timeLeft = this.rs.durationSpecified - currentTotal;

            if (this.rs.timeLeft < 0 && this.rs.hasNotified == false) {
                this.rs.hasNotified = true;
                this.sendNotification();
            }
        }

        //save to local storage incase browser dies.
        if (this.timerState.runState == "not_started") {
            localStorage.setItem("timer_state", "");

        } else {
            localStorage.setItem("timer_state", JSON.stringify(this.rs));
        }

        if (this.rs.timeLeft < 0) {
            document.title = "finished";
        } else {
            document.title = this.timerState.runState
        }

        setTimeout(this.handleTimeCheck, 500);
    }

    @action handleSaveTime = async () => {

        let result = await StoreTimeEntries.inst.saveTimeEntry(this.rs.timeCreated, this.rs.curTotalTime, this.rs.curCategory);

        if (result) {
            this.actionResetRS();
        }
    }


    @action actionHandleDelete = () => {
        let res = prompt("are you sure you want to delete the curren time?, yes/no");
        if (res == "yes") {
            this.actionResetRS();
        }
    }

    genDefaultRS(cat = "none") {
        return {
            timeInput: new TextInputState("1h"),
            lastStartTime: -1, //for pausing
            timeLeft: -1,
            durationSpecified: -1,
            totalTime: 0,
            curCategory: cat,
            curTotalTime: 0,
            hasNotified: false,
            timeCreated: -1,
            notificationState: {
                showStarted: -1,
                closedByCode: false,
            }
        }
    }



    //when you can update notifications use this -> https://developers.google.com/web/ilt/pwa/introduction-to-push-notifications
    @action
    sendNotification = () => {

        if (!Notification) return;

        requestionNotification();



        this.rs.notificationState.showStarted = Date.now();

        var notification = new Notification("Times up you did it", {
            body: 'You are a big sexy beast',
            requireInteraction: true
        });
        this.curNotification = notification;


        notification.onclick = (e) => {
            //notification.close();
        }

        notification.onclose = (e) => {
            // //close will be called when we close the notification from the click.
            // if(this.rs.notificationState.closedByCode == true) return;

            //make sure the timer is still running, otherwise that means we've already done something
            if (this.timerState.runState != "not_started") {
                this.handleSaveTime();
            }

        }
    }

    @computed get timerState() {

        let runState: TIMER_RUN_STATE = "not_started";
        if (this.rs.lastStartTime === -1 && this.rs.durationSpecified === -1) {
            runState = "not_started";
        } else if (this.rs.lastStartTime === -1 && this.rs.durationSpecified !== -1) {
            runState = "paused";
        } else {
            runState = "running";
        }


        return {
            runState,
            totalTime: this.rs.totalTime,
            durationSpecified: this.rs.durationSpecified,
            timeLeft: this.rs.timeLeft,
        }
    }

    // @action
    // actionSaveAndRestart=async (autoadd=false)=>{
    //     await this.handleSaveTime();
    //     await SCF_waitFor(()=>this.timerState.runState=="not_started",20000);
    //     this.actionStartTimeWithDuration("1h",autoadd);
    // }

    @action
    actionStartTimeWithDuration(durationString: string, autoadd = false) {
        if (this.timerState.runState != "not_started") {
            alert("can't start timer if it's already started");
            return;
        }

        if (autoadd) {
            this.autoAddedCount++;
        } else {
            this.autoAddedCount = 0;
        }
        //only auto add up to three things.
        if (this.autoAddedCount >= 3) {
            return;
        }

        //just do this here to be safe;
        this.actionResetRS();
        this.rs.timeCreated = Date.now();
        this.rs.lastStartTime = Date.now();
        this.rs.durationSpecified = HelperTime.shortTimeStringToMS(durationString);
    }

    @action
    actionResetRS() {
        if (this.rs.curCategory) {
            this.rs = this.genDefaultRS(this.rs.curCategory);
        } else {
            this.rs = this.genDefaultRS();
        }
    }

    @action
    handleAddClick = () => {
        let result = prompt("Add time, use - to add negative time. use 1h0m format");
        if (result) {

            let multiplyBy = 1;
            if (result[0] == "-") {
                multiplyBy = -1;
                result = result.substr(1);
            }

            let timeToAdd = HelperTime.shortTimeStringToMS(result);
            if (timeToAdd == -1) return alert("issue with time string:" + result);

            timeToAdd = timeToAdd * multiplyBy;
            this.actionAddTotalTime({ amountMS: timeToAdd });
        }
    }

    @action
    actionAddTotalTime = SA.createActionFunction("AT_UPDATE_TOTAL_TIME", (data: { amountMS: number }) => {
        this.rs.totalTime += data.amountMS;
    })


    @action
    actionStartClicked = () => {

        if (this.timerState.runState == "not_started") {
            this.actionStartTimeWithDuration(this.rs.timeInput.value)
        } else if (this.timerState.runState == "running") {
            //means we need to pause
            this.rs.totalTime += Date.now() - this.rs.lastStartTime;
            this.rs.curTotalTime = this.rs.totalTime;
            this.rs.lastStartTime = -1;
        } else {
            //means we were paused and now we need to run.
            this.rs.lastStartTime = Date.now();
        }
    }

    @computed
    get html_startButtonText() {
        let state = this.timerState.runState;
        if (state == "not_started") return "Start";
        if (state == "paused") return "Unpause";
        return "Pause";
    }

    @computed
    get html_timeLeft() {
        if (this.rs.timeLeft == -1) return "Not Started";
        return HelperTime.msToShortTimeString(this.rs.timeLeft);
    }

    @computed
    get html_timeTotal() {
        if (this.rs.curTotalTime == 0) return "Not Started";
        return HelperTime.msToShortTimeString(this.rs.curTotalTime);
    }

    @computed
    get html_timerNiceStatus() {
        return this.timerState.runState.split("_").join(" ");
    }


    renderDropDown() {

        return (
            <FormControl>
                <InputLabel>Category</InputLabel>
                <Select

                    value={this.rs.curCategory}
                    onChange={e => this.rs.curCategory = e.target.value as any}
                >

                    {StoreTimeEntries.inst.comp_categoriesInOrder.map(cat => {
                        return <MenuItem value={cat.name}>{cat.name}</MenuItem>
                    })}

                </Select>
            </FormControl>
        )
    }



    render() {


        return (


            <ElemFlexRow v="cen" h="sb" className={cx(css({ borderBottom: "1px solid darkgray", padding: 10 }))}>

                <ElemFlexRow v="cen" className={cx(css({ "&&>*": { marginRight: 20 } }))} >
                    <div className={cx(SG.font(20, "black", "bold", "uppercase"))}>{this.html_timerNiceStatus}</div>

                    {this.timerState.runState == "not_started" && <TextField className={cx(css({ input: { padding: 10 } }))} style={{ width: 70 }} label="Time" variant="outlined" {... this.rs.timeInput} />}
                    <Button onClick={this.actionStartClicked} variant="outlined" size="small">{this.html_startButtonText}</Button>

                    {this.timerState.runState != "not_started" && <div>{`Time Left:`}<span className={cx(css({ opacity: 0, ":hover": { opacity: 1, cursor: "none" } }))}>{this.html_timeLeft}||{this.rs.timeLeft}</span></div>}
                    {this.timerState.runState != "not_started" && <div>{`Total Time:`}<span className={cx(css({ opacity: 0, ":hover": { opacity: 1, cursor: "none" } }))}>{this.html_timeTotal}</span></div>}

                    {this.timerState.runState != "not_started" &&
                        <IconButton onClick={this.handleAddClick} size="small" >
                            <AddIcon />
                        </IconButton>
                    }


                    {this.timerState.runState == "paused" && <Button style={{ boxShadow: "none" }} color="primary" onClick={this.handleSaveTime} size="small" variant="contained">Save</Button>}
                    {this.timerState.runState == "paused" && <Button style={{ boxShadow: "none" }} color="secondary" onClick={this.actionHandleDelete} size="small" variant="contained">Delete</Button>}

                    {this.renderDropDown()}
                </ElemFlexRow>



                <div className={cx(css({ float: "right" }))}>
                    <IconButton onClick={() => StoreTimeEntries.inst.elemState.dialogCategoriesShowing = true} size="small" >
                        <FormatListBulletedIcon />
                    </IconButton>
                </div>





            </ElemFlexRow>






        );
    }
}
