

import * as React from "react";
import { observer } from "mobx-react";
import * as ReactRouter from "react-router";
import { History } from "history";
import { SLS } from "../stores/StoreLocation";
import { PageGlobal } from "./page-global/PageGlobal";
import { PagePowerHourTrack } from "./page-power-hour-track/PagePowerHourTrack";

interface PageMainProps {}
interface PageMainState {}

window.addEventListener('popstate', function(e){
    console.log('location changed!',window.location.pathname,e);
})



let count = 0;

@observer
export class PageMain extends React.Component<PageMainProps, PageMainState> {



    constructor(props: any) {
        super(props);
    }

    handleGlobalRouteOnChange=(prevState:ReactRouter.RouterState,nextState:ReactRouter.RouterState)=>{
        SLS.inst.setPath(nextState.location.pathname);
    }

    handleGlobalRouteOnEnter=(nextState:ReactRouter.RouterState)=>{
        SLS.inst.setPath(nextState.location.pathname);
    }


    

    render() {
        

        
        return(
            <ReactRouter.Router history={ReactRouter.browserHistory }>
                <ReactRouter.Route path="" component={PageGlobal as any} onChange={this.handleGlobalRouteOnChange} onEnter={this.handleGlobalRouteOnEnter}>
                    <ReactRouter.Route path="/phtrack"  component={PagePowerHourTrack} />
                    {/* <ReactRouter.IndexRedirect to={"/phtrack"} /> */}
                    <ReactRouter.Redirect from="*" to={"/phtrack"} />
                </ReactRouter.Route>

                
            </ReactRouter.Router>
        );
    }
}
