import { Json } from './../stores/StoreActions';
import { HelperTime } from './../helpers/HelperTime';
import { FSModelMetaBase } from "./FSModelMetaBase";
import { DateTime } from "luxon";

export interface FSModelTimeEntry extends Json{
    uuid:string;
    dateCreated:number;
    totalDurationMS:number;
    category:string;
}

export const FSModelTimeEntryMeta:FSModelMetaBase<FSModelTimeEntry>={
    collectionName:"ph-time-entries"
}

export function convertTimeEntryToString(te:FSModelTimeEntry){

    if(te==null){
        te={
            dateCreated:Date.now(),
            category:"none",
            totalDurationMS:0,
            uuid:"AUTO FILLED DELETE"
        }
    }

    let dt = DateTime.fromMillis(te.dateCreated);

    let pieces:string[] = [];

    pieces.push(dt.toFormat("L/d h:mma"));
    pieces.push(HelperTime.msToShortTimeString(te.totalDurationMS));
    pieces.push(te.category || "none");

    //test


    return pieces.join("   ");
}

export function createTimeEntryFromString(uuid:string,data:string):FSModelTimeEntry{
    let split = data.split("   ");
    let dateCreated = DateTime.fromFormat(split[0], "L/d h:mma").toJSDate().getTime();
    if(isNaN(dateCreated)){
        throw new Error("Error creating time entry from string, date created is wrong");
    }

    let totalDurationMS = HelperTime.shortTimeStringToMS(split[1]);
    if(totalDurationMS == -1){
        throw new Error("Error creating time entry, duration is wrong");
    }
    

    return{
        uuid,
        dateCreated,
        totalDurationMS,
        category:split[2]
    }
}