export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;

export const HelperTime = new class{

    shortTimeStringToMS(sts:string):number{

        //remove spaces
        sts = sts.split(" ").join("").toLowerCase();

        //calc hours
        let numHours = 0;
        let hSplit = sts.split("h");
        if(hSplit.length == 2){
            numHours = parseInt(hSplit[0]);
            if(isNaN(numHours))return -1;
            sts = hSplit[1]
        }else{
            sts = hSplit[0]
        }

        let stsInt = parseInt(sts.split("m").join("") || "0");
        if(isNaN(stsInt)) return -1;
        
        return (numHours * ONE_HOUR) + (stsInt * ONE_MINUTE);
    }

    msToShortTimeString(ms:number):string{

        if(ms < 0) return "0m";
        
        let numHours = Math.floor(ms / ONE_HOUR);

        let numMinutes = Math.ceil( (ms - (numHours * ONE_HOUR)) / ONE_MINUTE );

        if(numHours > 0){
            return `${numHours}h${numMinutes}m`;
        }
        return `${numMinutes}m`;
    }

}