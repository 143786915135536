

import * as React from "react";
import { observer } from "mobx-react";
import { NiceTimeEntry, DAYS, DAY_NAMES, DayAgg, StoreTimeEntries } from "../../../services/ph/StoreTimeEntries";
import { ElemFlexCol } from "../../common/alignment/ElemFlexCol";
import { cx, css } from "emotion";
import { SG } from "../../../helpers/StyleGen";
import { ONE_HOUR } from "../../../helpers/HelperTime";

interface ElemPHGraphColumnProps {
    entries: DayAgg,
    day: DAYS,
    onClick: () => void,
}
interface ElemPHGraphColumnState { }




@observer
export class ElemPHGraphColumn extends React.Component<ElemPHGraphColumnProps, ElemPHGraphColumnState> {

    constructor(props: any) {
        super(props);
    }

    renderEntries(entries: NiceTimeEntry[], bgColor: string, borderColor: string) {

        

       

        let blockElements: JSX.Element[] = [];

        const drawBlock = (fractionalNumber:number,bgColor:string,key:string)=>{
            let height = Math.ceil(fractionalNumber * 35);
            let width = 35;
            return (
                <div key={key} className={cx(css({ borderRadius:3, width: width, height: height, backgroundColor: bgColor }))}></div>
            )
        }

        const drawBlocks = (fractionalNumber:number,bgColor:string)=>{

            let wholeBlocks = Math.floor(fractionalNumber);
            let partialBlocks = fractionalNumber - wholeBlocks;

            for (let i = 0; i < wholeBlocks; i++) {
                blockElements.push(drawBlock(1,bgColor,bgColor+i))
            }
    
            if (partialBlocks>.4) {
                blockElements.push(drawBlock(.5,bgColor,bgColor+"partial"))
            }
        }


        //break up the categories.
        let cats = StoreTimeEntries.inst.comp_categoriesInOrder;

        for(let cat of cats){
            let totalDurationMS = entries.filter(ent=>ent.origData.category==cat.name).reduce((agg, ent) => agg += ent.origData.totalDurationMS, 0);
            if(totalDurationMS > 0){
                drawBlocks(totalDurationMS / ONE_HOUR,cat.color);
            }
        }

        //code starts here
        

   

        return blockElements;

    }





    render() {


        return (
            <ElemFlexCol v="top" h="cen" n100 className={cx(css({ flexDirection: "column-reverse", width: 70 }))}>

                <div onClick={this.props.onClick} className={cx(SG.font(16, "black", "bold", 'uppercase'), css({ cursor: "pointer" }))}>{DAY_NAMES[this.props.day].substr(0, 3)}</div>
                <div className={cx(SG.w100(), SG.margin(10, 0, 10), css({ borderTop: "1px solid black" }))}></div>

                <ElemFlexCol v="top" h="cen" className={cx(css({ flexDirection: "column-reverse", minHeight: 400, ">*": { marginTop: 5 } }))}>

                    {this.renderEntries(this.props.entries.entries, "green", "black")}

                </ElemFlexCol>


            </ElemFlexCol>
        );
    }
}
