

import * as React from "react";
import { observer } from "mobx-react";
import { ElemFlexCol } from "../common/alignment/ElemFlexCol";
import { ElemAddTime } from "./ElemAddTime";
import { StoreTimeEntries } from "../../services/ph/StoreTimeEntries";
import { ElemPHGraph } from "./elem-graph/ElemPHGraph";
import { Dialog, TextField, Button } from "@material-ui/core";
import { observable, action } from "mobx";
import { cx, css } from "emotion";
import { ElemFlexRow } from "../common/alignment/ElemFlexRow";
interface PagePowerHourTrackProps { }
interface PagePowerHourTrackState { }




@observer
export class PagePowerHourTrack extends React.Component<PagePowerHourTrackProps, PagePowerHourTrackState> {

    @observable rs = {
        categories: {
            text:""
        }
    }

    constructor(props: any) {
        super(props);
    }

    @action
    handleCategoriesSave=()=>{
        StoreTimeEntries.inst.actionSetCategories(JSON.parse(this.rs.categories.text));
    }

    @action
    handleCategoriesLoad=async()=>{
        let result = await StoreTimeEntries.inst.loadCategories();
        this.rs.categories.text = JSON.stringify(result,null,4);
    }



    render() {

        let sts = StoreTimeEntries.inst;


        return (
            <ElemFlexCol>
                <ElemAddTime />
                <ElemPHGraph />
                <Dialog open={StoreTimeEntries.inst.elemState.dialogCategoriesShowing} onClose={() => StoreTimeEntries.inst.elemState.dialogCategoriesShowing = false} >
                    <ElemFlexCol className={cx(css({ width: 400, padding: 20 }))}>

                        <TextField
                            fullWidth
                            label="Categories JSON"
                            multiline
                            rows="12"
                            variant="outlined"
                            value={this.rs.categories.text}
                            onChange={e=>this.rs.categories.text=e.target.value}
                        />

                        <ElemFlexRow>
                            <Button onClick={this.handleCategoriesLoad} variant="outlined">Load</Button>
                            <Button onClick={this.handleCategoriesSave} variant="outlined">Save</Button>

                        </ElemFlexRow>


                    </ElemFlexCol>

                </Dialog>
            </ElemFlexCol>
        );
    }
}
