import { FS_CATEGORIES_CONFIG_COLLECTION, FS_CATEGORIES_CONFIG_ID, PHCategory } from './../../models/FSModelCategoriesConfig';
import { SA } from './../../stores/StoreActions';
import { SimpleFirestoreManger } from './../firestore/SimpleFirestoreManager';
import { ONE_DAY, ONE_HOUR } from './../../helpers/HelperTime';
import { ObjectMap } from "../firestore/SimpleFirestoreManager";
import { FSModelTimeEntry, FSModelTimeEntryMeta, convertTimeEntryToString, createTimeEntryFromString } from "../../models/FSModelTimeEntry";
import { C_FirestoreObjectsHelper } from "../firestore/C_FirestoreObjectsHelper";
import { observable, computed, action } from "mobx";
import { SCF_genUUID } from '../SkillCappedFunctions';
import { computedFn } from "mobx-utils"
import _ from 'lodash';
import { DateTime } from 'luxon';
import { FSModelCategoryConfig } from '../../models/FSModelCategoriesConfig';




function getIsoDayString(d: Date | number) {
    if (typeof d == "number") {
        d = new Date(d);
    }
    return d.toISOString().split("T")[0]
}

export enum DAYS {
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY
}

export const DAYS_IN_ORDER = [DAYS.MONDAY, DAYS.TUESDAY, DAYS.WEDNESDAY, DAYS.THURSDAY, DAYS.FRIDAY, DAYS.SATURDAY, DAYS.SUNDAY];

export const DAY_NAMES = {
    [DAYS.MONDAY]: "Monday",
    [DAYS.TUESDAY]: "Tuesday",
    [DAYS.WEDNESDAY]: "Wednesday",
    [DAYS.THURSDAY]: "Thursday",
    [DAYS.FRIDAY]: "Friday",
    [DAYS.SATURDAY]: "Saturday",
    [DAYS.SUNDAY]: "Sunday",
}


export interface NiceTimeEntry {
    origData: FSModelTimeEntry;
}

export interface DayAgg {
    day: DAYS,
    dayKey:string,
    totalTimeMS: number,
    entries:NiceTimeEntry[]
}

export interface WeeklyAgg {
    days: DayAgg[]
}

export const DAY_KEY_FORMAT = "yyyy-LL-dd"



export class StoreTimeEntries {
    private static _inst: StoreTimeEntries;

    public static get inst(): StoreTimeEntries {
        if (!StoreTimeEntries._inst) StoreTimeEntries._inst = new StoreTimeEntries();
        return StoreTimeEntries._inst;
    }

    @observable
    private state_timeEntries: ObjectMap<FSModelTimeEntry> = {};
    timeEntriesQueryHelper: C_FirestoreObjectsHelper<FSModelTimeEntry>;

    //1 is last week.
    @observable
    state_curWeek=0;

    @observable state={
        categoriesConfig:null as null|FSModelCategoryConfig
    }

    @observable elemState={
        dialogCategoriesShowing:false
    }

    constructor() {
        this.timeEntriesQueryHelper = new C_FirestoreObjectsHelper<FSModelTimeEntry>(FSModelTimeEntryMeta.collectionName, arg => arg.uuid);
        let sixtyDaysAgo = Date.now() - (ONE_DAY * 60);
        this.timeEntriesQueryHelper.syncQueryAndDesubPrevQueries([["dateCreated", ">=", sixtyDaysAgo]], merger => {
            merger(this.state_timeEntries)
        })

        this.loadCategories();
    }

    @computed
    get comp_categoriesInOrder():PHCategory[]{
        let ret:PHCategory[] = [];

        let cc = this.state.categoriesConfig;
        if(cc){
            for(let [key,value] of Object.entries(cc.categories)){
                ret[value.order-1]=value;
            }
        }

        return ret;
    }

    async saveTimeEntry(dateCreated: number, totalDurationMS: number, category = "none") {
        let obj: FSModelTimeEntry = {
            dateCreated,
            totalDurationMS,
            category,
            uuid: SCF_genUUID()
        }

        return this.setTimeEntry({entry:obj});
    }

    setTimeEntry = SA.createActionFunction("SET_TIME_ENTRY_FROM_STORE",async (data:{entry:FSModelTimeEntry})=>{
        try {
            let obj = data.entry;
            let result = await SimpleFirestoreManger.saveDocument(FSModelTimeEntryMeta.collectionName, obj.uuid, obj, "Error saving time entry");
            return true;
        } catch (e) {
            alert("something went wrong saving");
            console.error(e);
            return false;
        }
    })


    actionSetCategories(data:FSModelCategoryConfig){
        SimpleFirestoreManger.getDb().collection(FS_CATEGORIES_CONFIG_COLLECTION).doc(FS_CATEGORIES_CONFIG_ID).set(data).then(()=>{
            this.loadCategories();
        })
    }

    
    @action
    actionSetCategoriesToState=SA.createActionFunction("SET_CATEGORIES_TO_STATE",(data:{data:FSModelCategoryConfig})=>{
        this.state.categoriesConfig = data.data;
    })


    async loadCategories():Promise<FSModelCategoryConfig>{
        let result = await SimpleFirestoreManger.getDb().collection(FS_CATEGORIES_CONFIG_COLLECTION).doc(FS_CATEGORIES_CONFIG_ID).get();
        let data = result.data() as any;
        this.actionSetCategoriesToState({data})
        return data
    }

    //will only recalculate if it changes.
    getTimeEntry = computedFn((uuid: string): NiceTimeEntry => {

        console.log("TIME ENTRY UPDATED");

        let entry = this.state_timeEntries[uuid];

        convertTimeEntryToString(entry);

        if (!entry) throw new Error("you shouldn't be getting an entry that doesn't exist, that means you have a stale uuid");

        return {
            origData: entry
        }
    })

    @computed
    get niceTimeEntriesInOrderNewestFirst() {
        let ret: NiceTimeEntry[] = [];

        for (let key in this.state_timeEntries) {
            ret.push(this.getTimeEntry(key));
        }

        ret = _.sortBy(ret, o => o.origData.dateCreated).reverse();
        return ret;
    }

    @computed
    get niceTimeEntryAggByDay(): { [key: string]: NiceTimeEntry[] } {

        let result: { [key: string]: NiceTimeEntry[] } = {}

        for (let entry of this.niceTimeEntriesInOrderNewestFirst) {

            
            let dayString = DateTime.fromMillis(entry.origData.dateCreated).toFormat(DAY_KEY_FORMAT);
            if (!result[dayString]) {
                result[dayString] = []
            }
            result[dayString].push(entry);
        }

        return result;
    }

    testAction = SA.createActionFunction("TEST_ACTION",(data:{stuff:string})=>{
        console.log(data);
    })

    getMondayFromDate(inD: number | Date) {

        let d = new Date(inD);

        let day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        let retDate = new Date(d.setDate(diff));
        retDate.setHours(0,0,0,0);
        return retDate.getTime();
    }

    @computed
    get thisMondayStartTime(): number {
        return this.getMondayFromDate(Date.now());
    }

    @computed get curWeeklyAgg():WeeklyAgg{
        let thisMonday = this.getMondayFromDate(Date.now());
        let correctMonday = thisMonday - (this.state_curWeek * 7 * ONE_DAY);
        return this.getWeeklyDataAgg(correctMonday);
    }


    


    getWeeklyDataAgg(mondayOfWeekStartTime: number): WeeklyAgg {

        let weeklyAgg: WeeklyAgg = {
            days: []
        };

        for (let curDayIndex = 0; curDayIndex < 7; curDayIndex++) {

            let curDay = mondayOfWeekStartTime + 1000 + (ONE_DAY * curDayIndex);
            let dayKey = getIsoDayString(curDay);
            let entries = this.niceTimeEntryAggByDay[dayKey] || [];

            let totalTime = entries.reduce((acc, cur) => acc + cur.origData.totalDurationMS, 0);

            weeklyAgg.days.push({
                day: DAYS_IN_ORDER[curDayIndex],
                totalTimeMS: totalTime,
                entries:entries,
                dayKey,
            })

        }


        return weeklyAgg;

    }


    @action actionModifyOrAddTimeEntry=SA.createActionFunction("MODIFY_OR_ADD_TIME_ENTRY_FROM_STORE",async (data:{uuid:string|null,editText:string})=>{
        
        
        try{
            if(data.uuid){

                if(data.editText.toLowerCase() == "delete"){
                    return SimpleFirestoreManger.getDb().collection(FSModelTimeEntryMeta.collectionName).doc(data.uuid).delete();
                }

                //modify
                let ent = this.state_timeEntries[data.uuid];
                if(!ent) throw new Error("time entry not found for uuid:"+data.uuid);
    
                let newEntry = createTimeEntryFromString(ent.uuid,data.editText);
                return this.setTimeEntry({entry:newEntry})
            }else{
                //add
                let newEntry = createTimeEntryFromString(SCF_genUUID(),data.editText);
                return this.setTimeEntry({entry:newEntry})
    
            }
        }
        catch(e){
            console.error(e);
            alert("error modifying or creating time entry");
        }

        
    })



}

