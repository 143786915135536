import { Json } from './../stores/StoreActions';

export const FS_CATEGORIES_CONFIG_COLLECTION="global-objects";
export const FS_CATEGORIES_CONFIG_ID="category_config";



export interface PHCategory extends Json{
    name:string;
    color:string;
    order:number;
}

export interface FSModelCategoryConfig extends Json{
    id:string,
    categories:{[categoryName:string]: PHCategory}
}

let test:FSModelCategoryConfig = {
    "categories": {
        "ltp": {
            "color": "#2e7d32",
            "name": "ltp",
            "order": 1
        },
        "none": {
            "color": "black",
            "name": "none",
            "order": 2
        }
    },
    "id": "category_config"
}
