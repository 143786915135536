

export function SCF_genUUID():string{
    function run(a?:any,b?:any){for(b=a='';a++<36;b+=a*51&52?(a^15?8^Math.random()*(a^20?16:4):4).toString(16):'-');return b}
    return run();
  }


export function SCF_secondsToTime(secs:number):{h:number,m:number,s:number}
{
    secs = Math.round(secs);
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    
    var obj = {
        "h": hours,
        "m": minutes,
        "s": seconds
    };
    return obj;
}

export function SCF_secondsToPrettyTime(secs:number):string{
    let obj = SCF_secondsToTime(secs);
    return `${(obj.h.toString().padStart(2,"0"))}:${(obj.m.toString().padStart(2,"0"))}:${(obj.s.toString().padStart(2,"0"))}`
}

export function SCF_timeAgo(date:Date){


        let diff = Date.now()-date.getTime();
        var seconds = Math.floor(diff / 1000);
      
        var interval = Math.floor(seconds / 31536000);
      
        if (interval > 1) {
          return interval + " years";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
          return interval + " months";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
          return interval + " days";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
          return interval + " hours";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
          return interval + " minutes";
        }
        return Math.floor(seconds) + " seconds";
      
}

export function SCF_waitFor(condition:()=>boolean, timeoutMS = 5000, errorMessage = "Error waiting for condition", checkTimeMS = 50){
  let startTime = new Date().getTime();


  return new Promise((resolve, reject) => {

      let timeoutFunc = () => {
          if (new Date().getTime() - startTime > timeoutMS) {
              return reject(new Error(errorMessage));
          }

          if (condition()) {
              return resolve();
          }

          setTimeout(timeoutFunc, checkTimeMS);
      }

      //Setup the timeout and do an initial check on condition
      timeoutFunc();
  });
}