

import * as React from "react";
import { observer } from "mobx-react";
import { cx, css, Interpolation } from "emotion";
import { ElemFlex } from "./ElemFlex";
import { T_FLEX_HORIZONTAL, T_FLEX_VERTICAL } from "../../../helpers/StyleGen";

interface ElemFlexRowProps {
    h?:T_FLEX_HORIZONTAL;
    v?:T_FLEX_VERTICAL;
    className?:string;
    h100?:boolean
    //not 100
    n100?:boolean
}
interface ElemFlexRowState {}




@observer
export class ElemFlexRow extends React.Component<ElemFlexRowProps, ElemFlexRowState> {

    constructor(props: any) {
        super(props);
    }

    


    render() {

        let cssIn:Interpolation<undefined> = {};
        if(this.props.h100 !== undefined){
            cssIn={height:"100%"}
        }

        
        return(
            <ElemFlex n100={this.props.n100} dir="row" v={this.props.v} h={this.props.h} className={cx(this.props.className,css(cssIn))}>
                {this.props.children}
            </ElemFlex>
        );
    }
}
