
import * as React from "react";
import { observer } from "mobx-react";

import { cx, css } from "emotion";
import { T_FLEX_DIRECTION, T_FLEX_HORIZONTAL, T_FLEX_VERTICAL, SG } from "../../../helpers/StyleGen";

interface ElemFlexProps {
    dir:T_FLEX_DIRECTION
    h?:T_FLEX_HORIZONTAL;
    v?:T_FLEX_VERTICAL;
    className?:string;
    //not 100% width
    n100?:boolean
}
interface ElemFlexState {}




@observer
export class ElemFlex extends React.Component<ElemFlexProps, ElemFlexState> {

    constructor(props: any) {
        super(props);
    }



    render() {
        let h:T_FLEX_HORIZONTAL = this.props.h || "left";
        let v:T_FLEX_VERTICAL = this.props.v || "top";
        
        let cssMain = css({width:"100%"});
        if(this.props.n100 === true)cssMain = "";
        
        return(
            <div className={cx("elem-flex",SG.flex(this.props.dir,h,v),cssMain,this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}
