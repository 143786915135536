
import { observable } from 'mobx';
import { ObjectMap, SyncedQueryResult, SimpleFirestoreManger } from './SimpleFirestoreManager';
import { FirestoreQuery } from './FirestoreQuery';


export type T_GET_ID_FUNC<T> = (arg:T)=>string;
export type T_MERGE_FS_DATA_FUNC<T> = (dataMap:ObjectMap<T>)=>void;
export type T_SYNCED_QUERY_CB<T> = (mergingFunc:T_MERGE_FS_DATA_FUNC<T>, syncedResults:SyncedQueryResult<T>)=>void;

export class C_FirestoreObjectsHelper<T>{
    collectionName:string;
    getId:T_GET_ID_FUNC<T>;
    unsub:(()=>void)|null=null;

    @observable hasLoaded = false;

    constructor(collectionName:string,getId:T_GET_ID_FUNC<T>){
        this.collectionName = collectionName;
        this.getId = getId;
    }

    dispose(){
        this.disposeSyncedQuery();
    }

    disposeSyncedQuery(){
        this.hasLoaded = false;
        if(this.unsub)this.unsub();
    }

    

    async saveObject(obj:T,errMsg?:string){
        if(!errMsg){
            try{
                errMsg = `error saving obj with uuid: ${this.getId(obj)} into collection: ${this.collectionName}`;
            }catch(e){
                errMsg = "error saving object, and error generating error message";
            }
            
        }
        if(!this.getId(obj)){
            throw new Error("couldn't get id for object to save");
        }

        return SimpleFirestoreManger.saveDocument(this.collectionName,this.getId(obj),obj,errMsg);
    }


    //Note* calling this twice will desync previous queries
    syncQueryAndDesubPrevQueries(query:FirestoreQuery<T>,cb:T_SYNCED_QUERY_CB<T>){
        this.disposeSyncedQuery();

        this.unsub = SimpleFirestoreManger.createSyncedMultiDocQuery<T>(this.collectionName,query,results=>{
            if(!this.hasLoaded)this.hasLoaded = true;
            let mergingFunction:T_MERGE_FS_DATA_FUNC<T> = (objectMap:ObjectMap<T>)=>{
                SimpleFirestoreManger.mergeQueryResultsIntoMap<T>(results,objectMap,this.getId);
            }
            cb(mergingFunction,results);
        })
    }

}