
import firebase from 'firebase'


export const ServiceFireStore =  new class {

    initHasRun=false;


    init(){

        if(this.initHasRun){
            throw new Error("trying to init firestore twice");
        }

        this.initHasRun = true;

        var firebaseConfig = {
            apiKey: "AIzaSyAqnaYjQSn6wXvJgbUAifGSoyKBD8FB7wU",
            authDomain: "traction-gg.firebaseapp.com",
            databaseURL: "https://traction-gg.firebaseio.com",
            projectId: "traction-gg",
            storageBucket: "traction-gg.appspot.com",
            messagingSenderId: "576344429819",
            appId: "1:576344429819:web:03153fe032d5f70fc34c5e"
          };
          firebase.initializeApp(firebaseConfig);
    }

    firestoreDb(){
        return firebase.firestore();
    }
}