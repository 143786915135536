
import * as React from "react";
import { observer } from "mobx-react";
import { StoreTimeEntries, DAYS_IN_ORDER, DayAgg, DAY_KEY_FORMAT } from "../../../services/ph/StoreTimeEntries";
import { ElemFlexRow } from "../../common/alignment/ElemFlexRow";
import { cx, css } from "emotion";
import { ElemPHGraphColumn } from "./ElemPHGraphColumn";
import { ElemFlexCol } from "../../common/alignment/ElemFlexCol";
import { observable, computed, action } from "mobx";
import { convertTimeEntryToString, FSModelTimeEntry } from "../../../models/FSModelTimeEntry";
import { SA } from "../../../stores/StoreActions";
import { Button } from "@material-ui/core";
import { DateTime } from "luxon";
interface ElemPHGraphProps { }
interface ElemPHGraphState { }




@observer
export class ElemPHGraph extends React.Component<ElemPHGraphProps, ElemPHGraphState> {


    @observable rs = {
        selectedDayKey: null as string | null,
        selectedTimeUUID: null as string | null,
        editTimeValue:"",
    }

    constructor(props: any) {
        super(props);
    }

    //use this to 
    @computed get comp_curAgg() {
        return StoreTimeEntries.inst.curWeeklyAgg;
    }


    renderCols() {

        let curAgg = StoreTimeEntries.inst.curWeeklyAgg;

        return (
            <ElemFlexRow h="cen" className={cx(css({ ">*": { marginRight: 10 } }))}>

                {DAYS_IN_ORDER.map((day, ind) => {
                    return <ElemPHGraphColumn onClick={()=>{
                        this.actionSelectDay({dayKey:curAgg.days[day].dayKey});
                    }} day={day} key={curAgg.days[day].dayKey} entries={curAgg.days[day]} />
                })}


            </ElemFlexRow>
        )

    }

    @action
    actionResetSelectDay(){
        //don't have a time entry selected anymore
        this.rs.selectedTimeUUID=null;

        if(!this.rs.selectedDayKey){
            this.rs.editTimeValue="";
        }else{
            let fakeDate=DateTime.fromFormat(this.rs.selectedDayKey,DAY_KEY_FORMAT).toJSDate();
            fakeDate.setHours(10,0,0);
            let fakeTE:FSModelTimeEntry = {
                dateCreated:fakeDate.getTime(),
                category:"none",
                totalDurationMS:0,
                uuid:"AUTO FILLED DELETE"
            }
    
            this.rs.editTimeValue = convertTimeEntryToString(fakeTE);
        }
        
    }

    
    @action
    actionSelectDay = SA.createActionFunction("SELECT_GRAPH_DAY",(data:{dayKey:string})=>{
        //if you select it when it's selected, close it
        if(data.dayKey == this.rs.selectedDayKey){
            this.rs.selectedDayKey = null;
        }else{
            this.rs.selectedDayKey = data.dayKey;
            this.actionResetSelectDay();
        }
        
        
    })

    @action
    actionSelectTimeEntry=SA.createActionFunction("SELECT_GRAPH_TIME_ENTRY",(data:{uuid:string})=>{
        
        //if we clicked it twice
        if(this.rs.selectedTimeUUID == data.uuid){
            this.rs.selectedTimeUUID=null;
            this.actionResetSelectDay();
        }else{
            
            //find this entry.
            for(let day of this.comp_curAgg.days){
                for(let ent of day.entries){
                    if(ent.origData.uuid == data.uuid){
                        this.rs.selectedTimeUUID=data.uuid;
                        this.rs.editTimeValue = convertTimeEntryToString(ent.origData);
                        return;
                    }
                }
            }

            //
            console.error("selected time entry not found")
        }
        
    })

    @action actionModifyOrAddTimeEntry=SA.createActionFunction("MODIFY_OR_ADD_TIME_ENTRY_FROM_GRAPH",(data:{})=>{
        StoreTimeEntries.inst.actionModifyOrAddTimeEntry({uuid:this.rs.selectedTimeUUID,editText:this.rs.editTimeValue});
        this.actionResetSelectDay();
    })


    renderEditBox() {

        if (this.rs.selectedDayKey == null) return null;
        //find our day;

        let curAgg = this.comp_curAgg;
        let curDay: DayAgg | null = null;
        for (let day of curAgg.days) {
            if (day.dayKey == this.rs.selectedDayKey) {
                curDay = day;
                break;
            }
        }

        //Our selected day is on a different week.
        if (curDay == null) return null;

        let fontCSS = cx("code-font", css({fontSize:16, fontWeight:"bold"}));


        return (
            <ElemFlexCol h="cen" className={cx(css({ border: "1px solid black", marginTop: 20, maxWidth:700 }))}>

                <ElemFlexRow n100 className={cx(css({marginTop:20, marginBottom:20}))}>
                    <input value={this.rs.editTimeValue} onChange={e=>this.rs.editTimeValue=e.target.value} className={cx(fontCSS, css({width:260, padding:5}))} type="text" />
                    <Button onClick={()=>this.actionModifyOrAddTimeEntry({})} variant="text" size="small"> {this.rs.selectedTimeUUID?"Modify":"Add"}</Button>
                </ElemFlexRow>
                


                <ElemFlexCol h="cen">
                    {curDay.entries.map(ent => {

                        return (
                            <ElemFlexRow n100 v="cen" className={cx(css({marginBottom:20}))}>
                                <div className={cx(fontCSS,css({ whiteSpace: "pre-line" }))}>{convertTimeEntryToString(ent.origData)}</div>
                                <Button onClick={()=>this.actionSelectTimeEntry({uuid:ent.origData.uuid})} variant="text" size="small">Edit</Button>
                            </ElemFlexRow>

                        )
                    })}
                </ElemFlexCol>


            </ElemFlexCol>
        )
    }


    render() {


        return (
            <ElemFlexCol h="cen" className={cx(css({ minHeight: 800 }))}>
                {this.renderCols()}
                {this.renderEditBox()}

            </ElemFlexCol>
        );
    }
}
